import React, {useContext, useEffect, useState} from "react";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import QuestionModel from "../../../../../models/Question";
import TextFieldCustom from "../../../../Core/Modules/Input/TextFieldCustom";
import HttpApi from "../../../../../httpApi";
import {createErrorNotification, notifyError, notifySuccess} from "../../../../../Utils/Notification";
import MenuItem from "@mui/material/MenuItem";
import {Row, Col, Button, Card, Badge} from "react-bootstrap";
import _ from "lodash";
import {getLanguage, useTranslation} from "react-multi-lang";
import LoadingView from "../../../../Core/Layouts/LoadingView";
import DeleteModal from "../../../../Core/Modules/Modal/DeleteModal";
import {Colors} from "../../../../../Utils/Colors";
import ConfirmModal from "../../../../Core/Modules/Modal/ConfirmModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {useUserData} from "../../../../../hooks/userHook";
import CreateClickAndBloomTour from "../../../../../Utils/ProductTour/Tours/CreateClickAndBloom/CreateClickAndBloomTour";
import {ProductTourContext} from "../../../../../Utils/Context/ProductTourContext";
import {FullContrastPurpleTextField, TourType} from "../../../../../Utils/Global";
import Login from "../../../../../login/login";


const SubmitCustom = (props) => {
    const t = useTranslation();
    const user = useUserData();

    const [tags, setTags] = useState([]);
    const [questions, setQuestions] = useState([{text_en:"", id_tag:-1, company:-1}]);
    const [credits, setCredits] = useState(0);
    const [creditsGroup, setCreditsGroup] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tipsOpen, setTipsOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const {updateStep,validateOutcome,tourStarted,tourActivated} = useContext(ProductTourContext);

    let CloneQuestionModel = _.cloneDeep(QuestionModel);
    CloneQuestionModel.text_en.form.multiline = false;
    CloneQuestionModel.text_en.form.label = "core.question";


    const email = user.email;

    const isSuperAdmin = user.roles.includes("global_super_admin") && user.companyGroup !== null

    const customCBExample = getLanguage() === "fr" ? "Je suis satisfait(e) du nouvel agencement de nos bureaux": getLanguage() === "nl" ? "Ik ben tevreden met de nieuwe indeling van onze bureaus" : "I am satisfied with the new layout of our offices."

    const handleCloseConfirm = () => {
        setConfirmOpen(false);
    }

    const handleCloseTips = () => {
        setTipsOpen(false);
    }


    const handleChange = (index) => event => {
        const name = event.target.name;
        const value = event.target.value;
        setQuestions(prevState => {
            let questions = [...prevState];
            questions[index][name] = value;
            return questions;
        })

    }

    const handleAdd = () => {
        setQuestions(prevState => {
            let questions = [...prevState];
            questions.push({text_en:"", id_tag:-1, company:-1});
            return questions;
        })
    }

    const handlePressSend = () => {

        checkDataValidity().then(() => {
            setConfirmOpen(true)
        }).catch(error => {
            createErrorNotification("Error!", error);
        })
    }

    const handleSave = () => {
        setLoading(true);
        checkDataValidity().then(() => {
            let newPrice = priceToPay();
            let newPriceGroup = null;
            let nbCreditUsed = credits - remainingCredits();
            if(nbCreditUsed > credits){
                nbCreditUsed = credits;
            }
            let nbCreditUsedGroup = null
            if(isSuperAdmin){
                nbCreditUsedGroup = creditsGroup - remainingCredits(true);
                newPriceGroup = priceToPay(true);
                if(nbCreditUsedGroup > creditsGroup){
                    nbCreditUsedGroup = creditsGroup;
                }
            }
            const data = {
                questions:questions,
                email:email,
                groupInfo: isSuperAdmin ? {name : user.companyGroup.name, id : user.companyGroup.id } : null,
                entityName : user.company ,
                credits:{
                    group:nbCreditUsedGroup,
                    company:nbCreditUsed
                },
                price:{
                    group:newPriceGroup,
                    company:newPrice
                }
            }
            let url = "/integration/mail/sendCustomQuestions"
            if(!user.roles.includes("global_admin") && isSuperAdmin){
                url = "/integration/mail/sendCustomQuestions/group"
            }
            if(!isSuperAdmin){
                data.questions.forEach(el => el.company = user.company)
            }
            HttpApi.post(url,data).then(result => {
                notifySuccess("Custom Click&Bloom successfully sent");
                setQuestions([{text_en:"", id_tag:-1, company:-1}])
                fetchCredits();
                if(tourActivated){
                    if(tourStarted){
                        updateStep(TourType.CREATE_CLICK_AND_BLOOM,5)
                    }else{
                        updateStep(TourType.CREATE_CLICK_AND_BLOOM,0)
                    }
                    validateOutcome(TourType.CREATE_CLICK_AND_BLOOM)
                }
            }).catch(error => {
                notifyError(error);
            }).finally(() => setLoading(false))
        }).catch(error => {
            createErrorNotification("Error!", error);
        }).finally(() => setLoading(false))

    }

    const checkDataValidity = () => {
        return new Promise((resolve, reject) => {
            if(questions.length === 0){
                reject("Please customize at least 1 Click&Bloom");
            }
            questions.forEach((question) => {
                if(!question.text_en || question.text_en.trim() === "" || question.id_tag === -1){
                    reject("Each Click&Bloom must contain text and tag");
                }
                if(isSuperAdmin) {
                    if(question.company === -1){
                        reject("Each Click&Bloom must contain text and tag and a selected company");
                    }
                }
            });
            resolve(true);
        })
    }

    const getTags = () => {
        HttpApi.getV2("/tags/getAll").then(response => {
            if(response && response.data){
                setTags(response.data);
            }else{
                setTags([]);
            }
        }).catch(error => {
            notifyError(error);
        })
    }

    const fetchCredits = () => {
        if(user.roles.includes("global_admin")){
            HttpApi.get("/setting/credits/getForCompany").then(response => {
                if(response && response.data){
                    setCredits(response.data.value);
                }else{
                    setCredits(0);
                }
            }).catch(error => {
                notifyError(error);
            })
        }
        if(isSuperAdmin){
            HttpApi.get("/setting/group/credits/getForCompany/" + user.companyGroup.id).then(responseGroup => {
                if(responseGroup && responseGroup.data){
                    setCreditsGroup(responseGroup.data.value);
                }else{
                    setCreditsGroup(0);
                }
            }).catch(error => {
                notifyError(error);
            })
        }

    }

    const handleDelete = (index) => event => {
        setQuestions(prevState => {
            let questions = [...prevState];
            questions.splice(index, 1);
            return questions;
        });
    }

    const remainingCredits = (forGroup = false) => {
        let totalValidCB = 0;
        totalValidCB = totalValidClickAndBloom(forGroup);
        if(forGroup){
            return creditsGroup-totalValidCB;
        }else{
            return credits-totalValidCB;
        }

    }
    const totalValidClickAndBloom = (forGroup = false) => {
        let totalValidCB = 0;
        questions.forEach(question => {
            if(isSuperAdmin){
                if(question.text_en && question.text_en.trim() !== "" && question.id_tag !== -1 && question.company === (forGroup ? user.companyGroup.name : user.company)){
                    totalValidCB++;
                }
            }else{
                if(question.text_en && question.text_en.trim() !== "" && question.id_tag !== -1){
                    totalValidCB++;
                }
            }

        })
        return totalValidCB;
    }

    const priceToPay = (forGroup = false) => {
        let creditsRemaining = remainingCredits(forGroup);
        if(creditsRemaining <= 0){
            return -(creditsRemaining*10);
        }
        return 0;
    }



    useEffect(() => {
        getTags();
        fetchCredits();
    }, [props]);

    return(
       <>
           <CreateClickAndBloomTour/>
           <CardDefault title={_.upperFirst(t("admin.submitCustomTitle"))} subtitle={_.upperFirst(t("admin.submitCustomHelper"))} rightButtonPurple={false} rightButtonVariant={"link"} buttonText={"Tips"} handleRightButton={() => setTipsOpen(true)} buttonMarginBugFix={25} tourDataset={{card : '',leftBtn:'', btn : 'tipsBtn', title : '',}} tourTooltip={true} tourType={TourType.CREATE_CLICK_AND_BLOOM}>
               <Card.Title style={{fontWeight:"normal", textAlign:"center", fontSize:"1.5em", color:Colors.EBLOOM_CONTRAST_PURPLE, fontFamily:"Lato"}}>
                   <div className={"w-full flex flex-col items-center justify-center mb-20"}>

                       {
                           user.roles.includes("global_admin") &&
                               <div className={"mb-10"}>
                                   {
                                       remainingCredits() > 0 ?
                                           <span className={"ebloom-text"}>{`${user.company} ` + _.upperFirst(t("admin.freeCB"))} <Badge bg={""} className={"bg-contrast-purple text-white"}>{remainingCredits()}</Badge></span>
                                           :
                                           <span className={"ebloom-text"}>{`${user.company} ` + _.upperFirst(t("admin.totalCart"))} <Badge bg={""} className={"bg-contrast-purple text-white"}>{priceToPay() + "€"}</Badge></span>

                                   }
                               </div>
                       }
                       {
                           isSuperAdmin &&
                           <div>
                               {
                                   remainingCredits(true) > 0 ?
                                       <span className={"ebloom-text"}>{`${user.companyGroup.name} ` + _.upperFirst(t("admin.freeCB"))} <Badge bg={""} className={"bg-contrast-purple text-white"}>{remainingCredits(true)}</Badge></span>
                                       :
                                       <span className={"ebloom-text"}>{`${user.companyGroup.name} ` + _.upperFirst(t("admin.totalCart"))} <Badge bg={""} className={"bg-contrast-purple text-white"}>{priceToPay(true) + "€"}</Badge></span>
                               }
                           </div>
                       }
                   </div>
               </Card.Title>
               {
                   loading ?
                       <LoadingView/>
                       :
                       questions.map((q, index) => (
                           <Row key={index}>
                               <Col data-createCB={'input'} md={ isSuperAdmin ? 7 : 9 }>
                                   <TextFieldCustom  size={"small"} model={CloneQuestionModel.text_en} value={q.text_en} handleChange={handleChange(index)} hasLabel={false} placeholder={customCBExample}/>
                               </Col>
                               <Col data-createCB={'select'} md={2}>
                                   <TextFieldCustom size={"small"} helper={false} model={QuestionModel.id_tag} value={q.id_tag} handleChange={handleChange(index)}>
                                       <MenuItem key={-1} value={-1} disabled>{_.upperFirst(t("core.tag.tag"))}</MenuItem>
                                       {
                                           tags.map((tag,index) => (
                                               <MenuItem key={index} value={t("core.tag." + tag.name)}>{t("core.tag." + tag.name)}</MenuItem>
                                           ))
                                       }
                                   </TextFieldCustom>
                               </Col>
                               {
                                   isSuperAdmin &&
                                   <Col md={2} className={"flex items-center"}>
                                       <FullContrastPurpleTextField value={q.company} name={"company"} select size={"small"} fullWidth onChange={handleChange(index)}>
                                           <MenuItem disabled={true} key={-1} value={-1}>Company</MenuItem>
                                           <MenuItem value={user.companyGroup.name}>{user.companyGroup.name}</MenuItem>
                                           {
                                               Login.hasRole("global_admin") &&
                                               <MenuItem value={user.company}>{user.company}</MenuItem>
                                           }

                                       </FullContrastPurpleTextField>
                                   </Col>
                               }
                               {
                                   questions.length > 1 &&
                                   <Col md={1} className={"flex items-center "}>
                                       <div className={"ml-10"}>
                                           <DeleteModal noRequest={true} handleDelete={handleDelete(index)}/>
                                       </div>
                                   </Col>
                               }

                           </Row>
                       ))
               }
            <div className={"grid grid-cols-3 items-center mt-20 justify-items-center"}>
                <AddCircleOutlineIcon className={"ebloom-add-btn-purple col-start-2 col-span-1"} data-createCB={"plus"} onClick={handleAdd}/>
                <div className={"col-span-1 justify-self-end"}>
                    <Button data-createCB={'confirm'} size={"sm"} className={"ebloom-btn-purple"} onClick={() => handlePressSend()}>{_.capitalize(t("core.send"))}</Button>
                </div>
            </div>
           </CardDefault>
           <ConfirmModal show={confirmOpen} handleClose={handleCloseConfirm} handleChange={handleSave} title={_.capitalize(t("admin.confirmSend"))} buttonRightText={_.capitalize(t("core.confirm"))} buttonLeftText={_.capitalize(t("core.cancel"))}>
               {
                   isSuperAdmin ?
                       _.upperFirst(t('admin.submitCustomConfirmGroup',{number:totalValidClickAndBloom(), price:priceToPay(),entity:user.company, numberGroup:totalValidClickAndBloom(true),priceGroup:priceToPay(true),group:user.companyGroup.name}))
                       :
                       _.upperFirst(t('admin.submitCustomConfirm',{number:totalValidClickAndBloom(), price:priceToPay()}))
               }
           </ConfirmModal>
           <ConfirmModal title={"Tips"} showButtons={false} size="lg" show={tipsOpen} handleClose={handleCloseTips}>
               <p className={"ebloom-text ebloom-large-text"} style={{color:Colors.EBLOOM_CONTRAST_PURPLE, fontWeight:500}}>{_.upperFirst(t("admin.submitCustomTipsTitle"))}</p>
               <div className={"ebloom-normal-text"}>
                   <li>{_.upperFirst(t('admin.submitCustomTips0'))}</li>
                   <li>{_.upperFirst(t("admin.submitCustomTips1"))}</li>
                   <li>{_.upperFirst(t("admin.submitCustomTips2"))}</li>
                   <li>{_.upperFirst(t("admin.submitCustomTips3"))}</li>
                   <li>{_.upperFirst(t("admin.submitCustomTips4"))}</li>
                   <li>{_.upperFirst(t("admin.submitCustomTips5"))}</li>
                   <li>{_.upperFirst(t("admin.submitCustomTips6"))}</li>
                   <li>{_.upperFirst(t("admin.submitCustomTips7"))}</li>
               </div>
           </ConfirmModal>

       </>
    )

};

export default SubmitCustom;