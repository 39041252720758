import React, {useCallback, useContext, useEffect, useState} from "react";
import update from "react-addons-update";
import {Button, Col, Row} from "react-bootstrap";
import {ReactComponent as PollIcon} from "../../../../../Core/symbols/webloom/white/poll-white.svg";
import {FullContrastBlueTextField, uploadFile, TourType, Roles} from "../../../../../../Utils/Global";
import {ReactComponent as PlusIcon} from "../../../../../Core/symbols/webloom/plus.svg";
import {ReactComponent as UniqueChoiceIcon} from "../../../../../Core/symbols/webloom/white/unique-choice-white.svg";
import {ReactComponent as MultipleChoicesIcon} from "../../../../../Core/symbols/webloom/white/multiple-choices-white.svg";
import {ReactComponent as YesNoIcon} from "../../../../../Core/symbols/webloom/white/yes-no-white.svg";
import {ReactComponent as StartRatingIcon} from "../../../../../Core/symbols/webloom/white/star-white.svg";
import {ReactComponent as UserIcon} from "../../../../../Core/symbols/user-solo-white.svg";
import {ReactComponent as TextIcon} from "../../../../../Core/symbols/webloom/white/text-white.svg";
import {ReactComponent as TitleIcon} from "../../../../../Core/symbols/webloom/white/title-white.svg";
import { InputAdornment, MenuItem } from '@mui/material';
import PollTypeCard from "./Modules/PollTypeCard";
import DraggableItem from "./Modules/DraggableItem";
import DropZone from "./Modules/DropZone";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {PollTypes, SurveyStatus, SurveyTypes} from "../Modules/Types";
import {DragAndDropItemV2} from "./Modules/DragAndDropItemV2";
import {PollQuestion} from "./Modules/PollQuestion";
import IconButton from "@mui/material/IconButton/IconButton";
import Menu from "@mui/material/Menu";
import _ from "lodash";
import PollFrame from "../Modules/PollFrame";
import AnswerPoll from "../../../../../Employee/Pages/WeBloom/Polls/Answer/Modules/AnswerPoll";
import {useTranslation} from "react-multi-lang/lib";
import {getLanguage} from "react-multi-lang";
import ConfirmModal from "../../../../../Core/Modules/Modal/ConfirmModal";
import HttpApi from "../../../../../../httpApi";
import {createErrorNotification, notifyError, notifySuccess} from "../../../../../../Utils/Notification";
import {useLocation, useParams} from "react-router-dom";
import moment from "moment";
import EbloomTimePicker from "../../../../../Core/Modules/Input/EbloomTimePicker";
import WeBloomTeamPicker from "../../Modules/WeBloomTeamPicker";
import {useNavigationTo} from "../../../../../../hooks/navigationHook";
import {useSavePage} from "../../../../../../hooks/savePageHook";
import LoadingView from "../../../../../Core/Layouts/LoadingView";
import FileInput from "../../../../../Core/Modules/Input/FileInput";
import FileDisplay from "../../../../../Core/Modules/Views-Tables-Lists/FileDisplay";
import {ProductTourContext} from "../../../../../../Utils/Context/ProductTourContext";
import {useUserData} from "../../../../../../hooks/userHook";
import EbloomSelectLang from "../../../../../Core/Modules/Input/EbloomSelectLang";
import EbloomBooleanSwitch from "../../../../../Core/Modules/Button/EbloomBooleanSwitch";
import {DropWrapperV2} from "./Modules/DropWrapperV2";
import FreeTrialModal from "../../../../../Core/Modules/Modal/FreeTrialModal";
import Checkbox from "@mui/material/Checkbox";
import WeBloomEntitiesPicker from "../../Modules/WeBloomEntitiesPicker";
import EbloomInfoTooltip from '../../../../../Core/Modules/Popovers-Tooltips/EbloomInfoTooltip';
import { Colors } from '../../../../../../Utils/Colors';
import WebloomDatePicker from "../../../../../Core/Modules/Input/WebloomDatePicker";

const WeBloomCreatePoll = (props) => {

    const [loading, setLoading] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showModalSend, setShowModalSend] = useState(false);
    const [loadingAttachment, setLoadingAttachment] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [loadingLogo, setLoadingLogo] = useState(false);

    const [id, setId] = useState(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus] = useState(SurveyStatus.DRAFT);
    const [team, setTeam] = useState(-1);
    const [lang, setLang] = useState(getLanguage());
    const [questions, setQuestions] = useState([]);
    const [targets, setTargets] = useState([]);
    const [recipients, setRecipients] = useState("allCurrentAndFutureUsers");
    const [attachmentUrl, setAttachmentUrl] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [isAnonymous, setIsAnonymous] = useState(true);
    const [logoUrl, setLogoUrl] = useState(null);
    const [hasEndedFreeTrial, setHasEndedFreeTrial] = useState(false);
    const [showFreeTrialModal, setShowFreeTrialModal] = useState(false);

    const [hasDeletedQuestion, setHasDeletedQuestion] = useState(false);
    const [hasNewQuestion, setHasNewQuestion] = useState(false);
    const [hasModifiedRecipients, setHasModifiedRecipients] = useState(false);
    const [sendNotification, setSendNotification] = useState(false);

    const [isGroup, setIsGroup] = useState(false);

    const params = useParams()

    const page = useSavePage(true);

    const types = [
        {type:PollTypes.TITLE, icon:TitleIcon},
        {type:PollTypes.NAME, icon:UserIcon},
        {type:PollTypes.UNIQUE_CHOICE, icon:UniqueChoiceIcon},
        {type:PollTypes.MULTIPLE_CHOICES, icon:MultipleChoicesIcon},
        {type:PollTypes.YES_NO, icon:YesNoIcon},
        {type:PollTypes.STAR_RATING, icon:StartRatingIcon},
        {type:PollTypes.OPEN_QUESTION, icon:TextIcon}
    ]

    const t = useTranslation();
    const location = useLocation();
    const navigation = useNavigationTo();
    const user = useUserData();
    const isProspect = user.module === "prospect"
    const {validateOutcome,tourActivated} = useContext(ProductTourContext)

    const isDraft =  null !== id;

    //getters
    const reloadSurvey = (id) => {
        let url = isGroup ? "/webloom/getSurvey/"+ id + '/' + user.companyGroup.id : "/webloom/getSurvey/" + id
        HttpApi.getV2(url).then(response => {
           if(response && response.data){
               setId(response.data.id);
               initiateSurvey(response.data);
           }else{
               navigation.forceTo(isGroup ? "/group/webloom" : "/admin/webloom");
           }
        })
    }

    const fetchEndDate = () => {
        if(user.module === "free"){
            HttpApi.getV2("/companies/getEndDate").then(response => {
                if(response && response.data){
                    setHasEndedFreeTrial(moment(response.data.end_date).isSameOrBefore(moment()));
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    }

    //posters
    const saveSurvey = (newStatus) => {
        if(!loading){
            setLoading(true);
            validateSurvey(newStatus === SurveyStatus.DRAFT).then(() => {
                const data = isDraft ?
                    {
                        id:id,
                        title:title,
                        description:description,
                        questions:questions,
                        type:SurveyTypes.POLL,
                        status: newStatus,
                        users:team === -1 ? "all" : targets,
                        recipients:recipients,
                        start_date:startDate,
                        end_date:endDate,
                        lang:lang,
                        attachment_url:attachmentUrl,
                        image_url:imageUrl,
                        is_anonymous: isAnonymous,
                        id_company : isGroup ? user.companyGroup.id : user.companyId,
                        logo_url: logoUrl,
                        send_notification:status !== SurveyStatus.RUNNING ?
                            true
                            : sendNotification
                    } :
                    {
                        title:title,
                        description:description,
                        questions:questions,
                        type:SurveyTypes.POLL,
                        status: newStatus,
                        users:team === -1 ? "all" : targets,
                        recipients: recipients,
                        start_date:startDate,
                        end_date: endDate,
                        lang:lang,
                        attachment_url:attachmentUrl,
                        image_url:imageUrl,
                        is_anonymous:isAnonymous,
                        id_company : isGroup ? user.companyGroup.id : user.companyId,
                        logo_url: logoUrl,
                        send_notification:status !== SurveyStatus.RUNNING ?
                            true
                            :
                            sendNotification
                    };
                let url = isDraft ? "/webloom/update" : "/webloom/create"
                const HttpCall = isDraft ? HttpApi.put : HttpApi.postV2;
                if(isGroup){
                    url += '/' + data.id_company;
                }
                HttpCall(url, data).then((response) => {
                    console.log(response);
                    page.save();
                    if(newStatus !== SurveyStatus.DRAFT){
                        if(tourActivated){
                            validateOutcome(TourType.LAUNCH_WEBLOOM)
                        }
                        notifySuccess(_.upperFirst(t((startDate ? "webloom.notification.surveyPlaned": "webloom.notification.surveySent"), {survey:t("webloom.poll")})));
                        navigation.forceTo(isGroup ? "/group/webloom/dashboard" : "/admin/webloom");
                    }else{
                        notifySuccess(_.upperFirst(t("webloom.notification.surveySaved", {survey:t("webloom.poll")})));
                        let id_survey = id || response.data;
                        reloadSurvey(id_survey) //survey need to be reloaded in order to get ids for updates
                    }
                }).finally(() => {setLoading(false)});
            }).catch(error => {
                createErrorNotification(_.upperFirst(t("core.notification.error")), error);
                setLoading(false);
            })
        }
    }

    //handlers
    const validateSurvey = (saveDraft) => {
        return new Promise((resolve, reject) => {
            if(!title){
                reject(_.upperFirst(t("webloom.notification.titleError")));
            }
            if(status !== SurveyStatus.RUNNING && startDate){
                if(moment(startDate).isSameOrBefore(moment())){
                    reject(_.upperFirst(t("webloom.notification.futurePollError")));
                }else if(moment(startDate).get("hour") < 8 || moment(startDate).get("hour") > 18 || moment(startDate).get("minute") % 5 !== 0){
                    reject(_.upperFirst(t("webloom.notification.startHourError")));
                }
            }
            if(questions.length === 0 || (!saveDraft && questions.length === 1 && questions[0].type === PollTypes.IDENTIFICATION)){
                reject(_.upperFirst(t("webloom.notification.numberQuestionError")));
            }
            questions.forEach((question) => {
                if(!question.title){
                    reject(_.upperFirst(t("webloom.notification.questionError")));
                }else if(question.type === PollTypes.UNIQUE_CHOICE|| question.type === PollTypes.MULTIPLE_CHOICES || question.type === PollTypes.YES_NO){
                    if(question.options.length < 2){
                        reject(_.upperFirst(t("webloom.notification.twoOptionError")));
                    }else{
                        question.options.forEach((option) => {
                            if(!option || !option.text){
                                reject(_.upperFirst(t("webloom.notification.optionError")));
                            }
                        })
                    }
                }
            })
            if(team !== -1 && targets.length < 5 && isAnonymous && !saveDraft){
                reject(_.upperFirst(t("webloom.notification.recipientsError", {param:t("webloom.poll")})))
            }
            if(team !== -1 && user.companyId === 177 && targets.length < 20 && isAnonymous && !saveDraft){//GSK limite à 20
                reject(_.upperFirst(t("webloom.notification.recipientsError", {param:t("webloom.poll")})))
            }
            resolve(true);
        })
    }

    const handleTitle = (event) => {
        setTitle(event.target.value);
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleDescription = (event) => {
        setDescription(event.target.value);
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleLang = (event) => {
        setLang(event.target.value);
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleIsAnonymous = () => {
        if(isAnonymous === true){
            if(questions.find(el => el.type === PollTypes.IDENTIFICATION) === undefined){
                handleAddQuestion({id:_.uniqueId(), type:PollTypes.IDENTIFICATION}, 0);
            }
        }else {
            if(questions.find(el => el.type === PollTypes.IDENTIFICATION) !== undefined) {
                handleRemoveQuestion(questions.find(el => el.type === PollTypes.IDENTIFICATION));
            }
        }
        setIsAnonymous(!isAnonymous);
        if(page.isSaved){
            page.unSave();
        }
    }

    let handleAddQuestion = (question, position) => {
        setQuestions(prevState => {
            let questions = [...prevState];
            question.new = true;
            if(questions.length > 0) {
                questions.splice(position, 0, question);
            }else{
                questions.push(question);
            }
            return questions;
        })
        if(page.isSaved){
            page.unSave();
        }
        if(status === SurveyStatus.RUNNING && hasNewQuestion === false){
            setHasNewQuestion(true);
        }
    }

    const saveQuestion = (question) => {
        setQuestions(prevState => {
            let questions = [...prevState];
            let index = questions.indexOf(questions.filter(el => el.id === question.id)[0]);
            if(typeof question.id !== "number"){
                question.id = parseInt(question.id);
            }
            questions.splice(index, 1, question);
            return questions;
        })
        if(page.isSaved){
            page.unSave();
        }
    }

    const duplicateQuestion = (question) => {
        setQuestions(prevState => {
            let questions = [...prevState];
            const index = questions.indexOf(questions.filter(el => el.id === question.id)[0]);
            let q = _.cloneDeep(question);
            q.id = _.uniqueId(Math.floor(Math.random()*100).toString());
            q.new = true;
            questions.splice(index+1, 0, q);
            return questions;
        })
        if(page.isSaved){
            page.unSave();
        }
        if(status === SurveyStatus.RUNNING && hasNewQuestion === false){
            setHasNewQuestion(true);
        }
    }

    const findItem = useCallback(
        (id) => {
            const question = questions.filter((c) => c.id === id)[0]
            return {
                question,
                index: questions.indexOf(question),
            }
        },
        [questions],
    )

    const moveItem= useCallback(
        (id, atIndex) => {
            const { question, index } = findItem(id)
            setQuestions(
                update(questions, {
                    $splice: [
                        [index, 1],
                        [atIndex, 0, question],
                    ],
                }),
            )
            if(page.isSaved){
                page.unSave();
            }
        },
        [findItem, questions, setQuestions],
    )

    const handleRemoveQuestion = (question) => {
        setQuestions(prevState => {
            let questions = [...prevState];
            let index = questions.indexOf(questions.filter(el => el.id === question.id)[0])
            questions.splice(index, 1);
            return questions;
        })
        if(page.isSaved){
            page.unSave();
        }
        if(status === SurveyStatus.RUNNING && hasDeletedQuestion === false){
            setHasDeletedQuestion(true);
        }
    }

    const handlePreview = () => {
        setIsPreview(!isPreview);
    }

    const handleReset = () => {
        setTitle(null);
        setDescription(null);
        setStartDate(null);
        setEndDate(null);
        setTeam(1);
        setLang(getLanguage());
        setQuestions([]);
        setAttachmentUrl(null);
        setImageUrl(null);
        setIsAnonymous(true);
        if(page.isSaved){
            page.unSave();
        }
        if(status === SurveyStatus.RUNNING && hasDeletedQuestion === false){
            setHasDeletedQuestion(true);
        }
    }

    const handleOpenModal = () => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const handleOpenModalSend = () => {
        validateSurvey(false).then(() => {
            setShowModalSend(true);
        }).catch(error => {
            createErrorNotification(_.upperFirst(t("core.notification.error")), error);
        })
    }

    const handleCloseModalSend = () => {
        setShowModalSend(false);
    }

    const handleOpenMenu = (event) => {
        setAnchorEl(event.target);
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    }

    const handleChangeTeam = (team, targets, recipients, staySaved=false) => {
        if(team !== null){
            setTeam(team);
        }
        if(targets !== null){
            setTargets(targets);
        }
        if(recipients !== null){
            setRecipients(recipients);
        }
        if(page.isSaved && !staySaved){
            page.unSave();
        }
        if(status === SurveyStatus.RUNNING && hasModifiedRecipients === false){
            setHasModifiedRecipients(true);
        }
    }

    const handleChangeStartDate = (date) => {
        //Change Hour Automatically to 12:00 if it's first time planning a start date
        if(!!date){
            if(!startDate){
                //Set new start date with new hour setting to 12:00
                let sd = moment(date).set("hour", 12).set("minute", 0).format("YYYY-MM-DD HH:mm")
                setStartDate(new Date(sd));
            }else{
                //Set new start date to previous hour setting
                const datetime = moment(startDate).format("HH:mm");
                let [hour, minutes] = datetime.split(":");
                let sd = moment(date).set("hour", parseInt(hour)).set("minute", parseInt(minutes)).format("YYYY-MM-DD HH:mm")
                setStartDate(new Date(sd));
            }
        }else{
            setStartDate(date);
        }
        if(moment(date).isAfter(moment(endDate))){
            setEndDate(null);
        }
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleChangeStartHour = (event) => {
        let [hour, minutes] = event.target.value.split(":");
        let sd = moment(startDate).set("hour", parseInt(hour)).set("minute", parseInt(minutes)).format("YYYY-MM-DD HH:mm")
        setStartDate(new Date(sd));
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleChangeEndDate = (date) => {
        setEndDate(date);
        if(page.isSaved){
            page.unSave();
        }
    }

    const handleLogo = (event) => {
        const file = event.target.files[0];
        if(file && !loadingLogo){
            setLoadingLogo(true);
            uploadFile(file).then((url) => {
                setLogoUrl(url)
                setLoadingLogo(false)
                notifySuccess("File uploaded!");
            }).catch(error => {
                setLogoUrl(null);
                setLoadingLogo(false);
                notifyError(error);
            })
        }else{
            setLogoUrl(null);
        }
        if(page.isSaved){
            page.unSave()
        }
    }

    const handleAttachment = (event) => {
        const file = event.target.files[0];
        if(file && !loadingAttachment){
            setLoadingAttachment(true);
            uploadFile(file).then((url) => {
                setAttachmentUrl(url);
                setLoadingAttachment(false)
                notifySuccess("File uploaded!");
            }).catch(error => {
                setAttachmentUrl(null);
                setLoadingAttachment(false);
                notifyError(error);
            })
        }else{
            setAttachmentUrl(null);
        }
        if(page.isSaved){
            page.unSave()
        }
    }

    const handleImage = (event) => {
        const file = event.target.files[0];
        if(file && !loadingImage){
            setLoadingImage(true);
            uploadFile(file).then((url) => {
                setImageUrl(url);
                setLoadingImage(false);
                notifySuccess("Image uploaded");
            }).catch(error => {
                setImageUrl(null);
                setLoadingImage(false);
                notifyError(error);
            })
        }else{
            setImageUrl(null);
        }
        if(page.isSaved){
            page.unSave()
        }
    }

    const displayRecipients = () => {
        switch (team){
            case -1:
                return _.upperFirst(t("core.collaborative.allCurrentAndFutureUsers"));
            case -3:
                return _.upperFirst(t("core.collaborative.allCurrentUsers"));
            case -2:
                return _.upperFirst(t("core.customized"));
            case -4:
                return null;
            default:
                return recipients
        }
    }

    const initiateSurvey = (survey) => {
        setTitle(survey.title);
        setDescription(survey.description);
        let starDateSurvey = null
        if(survey.start_date && survey.start_hour){
            const hour = survey.start_hour.split(':')
            starDateSurvey = moment(survey.start_date).set({'hour': parseInt(hour[0]), "minute": parseInt(hour[1])})
        }else if (survey.start_date){
            starDateSurvey = moment(survey.start_date).endOf('day')
        }

        if(survey.status !== SurveyStatus.RUNNING && starDateSurvey && moment(starDateSurvey,"YYYY-MM-DD HH:mm:ss").isBefore(moment())){
            setStartDate(null);
        }else{
            setStartDate(survey.start_date ? new Date(survey.start_date) : null);
        }
        if(survey.end_date && moment(survey.end_date).isSameOrBefore(moment())){
            setEndDate(null);
        }else{
            setEndDate(survey.end_date ? new Date(survey.end_date) : null);
        }

        setRecipients(survey.recipients);
        if(survey.recipients !== "allCurrentAndFutureUsers"){
            setTargets(survey.users);
        }
        setTeam(survey.recipients === "allCurrentAndFutureUsers" ? -1 : survey.recipients === "allCurrentUsers" ? -3 : survey.recipients === "customized" ? -2 : survey.recipients);
        let questions = survey.questions;
        for(let i=0;i<questions.length;i++){
            if(questions[i].answers){
                delete questions[i].answers;
            }
        }
        setQuestions(questions);
        setLang(survey.lang);
        setAttachmentUrl(survey.attachment_url);
        setImageUrl(survey.image_url);
        setIsAnonymous(Boolean(survey.is_anonymous));
        setStatus(survey.status);
        setLogoUrl(survey.logo_url);
    }

    useEffect(() => {
        fetchEndDate();
        setIsGroup(!!params.idGroup)
        if(location && location.state){
            const survey = location.state;
            const url = new URL(window.location.href);
            const duplicate = url.searchParams.get("duplicate");
            if(!duplicate){
                setId(survey.id);
            }if(survey.status !== SurveyStatus.DRAFT && survey.status !== SurveyStatus.SCHEDULED){
                let choices = survey.questions.filter(el => (el.type === PollTypes.UNIQUE_CHOICE || el.type === PollTypes.MULTIPLE_CHOICES || el.type === PollTypes.YES_NO) && el.show_other);
                if(choices){
                    choices.forEach((choice) => {
                        let index = choice.options.indexOf(choice.options.find(el => el.id === 0));
                        choice.options.splice(index, 1);
                    })
                }
                if(duplicate){
                    survey.status = SurveyStatus.DRAFT;
                }
                page.unSave();
            }else{
                page.unSave();
            }
            initiateSurvey(survey);
        }
    }, [params]);


    return (
        <>
            <PollFrame hideReset={status === SurveyStatus.RUNNING} type={SurveyTypes.POLL} isPreview={isPreview} handlePreview={handlePreview} handleReset={handleOpenModal} status={SurveyStatus.DRAFT} isSaved={page.isSaved} isGroup={isGroup}>
                {
                    isPreview ?
                        <AnswerPoll survey={{title:title, description:description, start_date:startDate, end_date:endDate, questions:questions, lang:lang, attachment_url:attachmentUrl, image_url:imageUrl, is_anonymous:isAnonymous}}/>
                        :
                        <div>
                            <Row>
                                <Col md={4} className={"flex items-center justify-center"}>
                                    <PollIcon className={"h-90 w-90 contrast-blue-icon"}/>
                                </Col>
                                <Col md={6}>
                                    <Row className={"items-center mt10 mb10"}>
                                        <Col md={3}>
                                            <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.title"))}</span>
                                        </Col>
                                        <Col>
                                            <FullContrastBlueTextField size={"small"} variant={"outlined"} placeholder={_.upperFirst(t("core.typeTitle"))} fullWidth value={title} onChange={handleTitle} InputProps={{endAdornment:<InputAdornment position={"end"}><EbloomInfoTooltip colors={{secondary:Colors.EBLOOM_CONTRAST_PURPLE}} size='sm' text={_.upperFirst(t("webloom.titleHelper"))}/></InputAdornment>}}/>
                                        </Col>
                                    </Row>
                                    {
                                        (user.roles.includes("global_backoffice")) &&
                                      <Row>
                                          <Col md={3}>
                                              <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("logo partenaire"))}</span>
                                          </Col>
                                          <Col className={"flex items-center"}>
                                              <FileInput className={"mr-5"} handleChange={handleLogo} showText={!logoUrl && !loadingLogo} iconColor={"contrast-blue"}/>
                                              {
                                                  loadingLogo ?
                                                    <LoadingView size={16}/>
                                                    :
                                                    logoUrl &&
                                                    <FileDisplay urlParams={logoUrl} handleDelete={() => {setLogoUrl(null)}} showIcon={false}/>
                                              }
                                          </Col>
                                      </Row>
                                    }
                                    <Row className={"items-center mt10 mb10"}>
                                        <Col md={3}>
                                            <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.description"))}</span>
                                        </Col>
                                        <Col>
                                            <FullContrastBlueTextField size={"small"} multiline minRows={4} variant={"outlined"} placeholder={_.upperFirst(t("core.typeText"))} fullWidth value={description} onChange={handleDescription}/>
                                        </Col>
                                    </Row>
                                    <Row className={"items-start mt10 mb10"}>
                                        <Col md={3}>
                                            <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.dates"))}</span>
                                        </Col>
                                        <Col className={"flex justify-between items-center "}>
                                            <Row className={"w-full flex justify-between items-center "}>
                                                <div className={"w-full flex justify-between items-center "} >
                                                     <span
                                                         className={"text-contrast-blue ebloom-text ebloom-small-text mr-5"}>{_.upperFirst(t("core.start"))}</span>
                                                    <div className={"w-3/4"}>
                                                        {
                                                            status === SurveyStatus.RUNNING ?
                                                                <div
                                                                    className={"text-contrast-blue ebloom-text ebloom-small-text"}>{moment(startDate).format("DD/MM/YY HH:mm")}</div>
                                                                :
                                                                <div>
                                                                    <WebloomDatePicker date={startDate}
                                                                                       handleDate={handleChangeStartDate}/>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    startDate &&
                                                    <div
                                                        className={"w-full ebloom-link-blue text-center"}
                                                        onClick={() => {
                                                            handleChangeStartDate(null)
                                                        }}>{_.upperFirst(t("core.now"))}</div>
                                                }
                                            </Row>

                                        </Col>
                                        <Col className={"flex justify-between items-center flex-row"}>
                                            <Row className={"w-full flex justify-between items-center "}>
                                                <div className={"w-full flex justify-between items-center "}>
                                                    <span
                                                        className={"text-contrast-blue ebloom-text ebloom-small-text mr-5"}>{_.upperFirst(t("core.end"))}</span>
                                                    <div className={"w-3/4"}>
                                                        <div>
                                                            <WebloomDatePicker date={endDate}
                                                                               handleDate={handleChangeEndDate} fromDate={startDate} placeholder={'core.never'}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    endDate &&
                                                    <div className={"w-full text-center ebloom-link-blue"}
                                                         onClick={() => {
                                                             handleChangeEndDate(null)
                                                         }}>{_.upperFirst(t("core.never"))}</div>
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                    {
                                        (null !== startDate && status !== SurveyStatus.RUNNING) &&
                                        <Row className={"items-center mt10 mb10"}>
                                            <Col md={3}>
                                                <span
                                                    className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.hour"))}</span>
                                            </Col>
                                            <Col>
                                                <EbloomTimePicker handleChange={handleChangeStartHour}
                                                                  selectedHour={(moment(startDate).isBefore(moment()) || moment(startDate).get("hour") < 8 || moment(startDate).get("hour") > 18 || moment(startDate).get("minute") % 5 !== 0) ? "select" : moment(startDate).format("HH:mm").toString()}
                                                                  isToday={moment(startDate).format("DD/MM/YY") === moment().format("DD/MM/YY")}/>
                                            </Col>
                                        </Row>
                                    }
                                    <Row className={"items-center mt10 mb10"}>
                                        <Col md={3}>
                                            <span
                                                className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("webloom.pollLanguage"))}</span>
                                        </Col>
                                        <Col>
                                            <EbloomSelectLang handleChange={handleLang} value={lang} color={"blue"}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <span className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("webloom.anonymousPoll"))}</span>
                                        </Col>
                                        <Col className={"flex items-center"}>
                                            <EbloomBooleanSwitch selected={isAnonymous} handleChange={handleIsAnonymous} color={"contrast-blue"} disabled={status === SurveyStatus.RUNNING}/>
                                        </Col>
                                    </Row>
                                    <Row className={"items-center mt10 mb10"}>
                                        <Col md={3}>
                                            <div className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("core.collaborative.recipients"))}
                                                {
                                                    targets && targets.length > 0 &&
                                                    <span className={"ml-5"}>{"("+targets.length+")"}</span>
                                                }
                                            </div>
                                        </Col>
                                        <Col>
                                            {
                                                isGroup ?
                                                    <WeBloomEntitiesPicker handleChange={handleChangeTeam} team={team} targets={targets} />
                                                    :
                                                    <WeBloomTeamPicker isProspect={isProspect} handleChange={handleChangeTeam} team={team} targets={targets}/>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className={"items-center mt-10 mb-10"}>
                                        <Col md={3}>
                                            <div className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("webloom.attachment"))}</div>
                                        </Col>
                                        <Col className={"flex items-center"}>
                                            <FileInput className={"mr-5"} handleChange={handleAttachment} showText={!attachmentUrl && !loadingAttachment} iconColor={"contrast-blue"}/>
                                            {
                                                loadingAttachment ?
                                                    <LoadingView size={16}/>
                                                    :
                                                attachmentUrl &&
                                                <FileDisplay urlParams={attachmentUrl} handleDelete={() => {setAttachmentUrl(null)}} showIcon={false}/>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className={"items-center mb-10 mt-10"}>
                                        <Col md={3}>
                                            <div className={"text-contrast-blue font-semibold"}>{_.upperFirst(t("webloom.image"))}</div>
                                        </Col>
                                        <Col className={"flex items-center"}>
                                            <FileInput className={"mr-5"} handleChange={handleImage} showText={!imageUrl && !loadingImage} iconColor={"contrast-blue"} type={"image"}/>
                                            {
                                                loadingImage ?
                                                    <LoadingView size={16}/>
                                                    :
                                                    imageUrl &&
                                                    <FileDisplay urlParams={imageUrl} handleDelete={() => {setImageUrl(null)}} showIcon={false}/>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr className={"text-contrast-blue bg-contrast-blue mb20"}/>
                            <DndProvider backend={HTML5Backend}>
                                <Row>
                                    <Col md={3} className={"relative"}>
                                        <div className={"sticky top-10"}>
                                            {
                                                types.map((type, index) => (
                                                    <DraggableItem key={index} itemToDrag={{type:type.type}} isNew={true} handleItem={handleAddQuestion} status={status}>
                                                        <PollTypeCard type={type} key={index}/>
                                                    </DraggableItem>

                                                ))
                                            }
                                        </div>
                                    </Col>
                                    <Col>
                                        {
                                            (!questions || questions.length === 0) &&
                                            <DropZone position={0} first={true}/>
                                        }
                                        {
                                            questions?.map((question, key) => (
                                                <div key={key}>
                                                    {
                                                        (key!==0 || question.type !== PollTypes.IDENTIFICATION) &&
                                                        <DropZone position={key}/>
                                                    }
                                                    {
                                                        (question.type === PollTypes.IDENTIFICATION) ?
                                                            <PollQuestion propQuestion={question} handleSaveQuestion={saveQuestion} handleRemoveQuestion={handleRemoveQuestion} handleDuplicateQuestion={duplicateQuestion} lang={lang} handleCanDrag={(value) => {}} isAnonymous={(questions.filter(el => el.type === PollTypes.NAME).length > 1 || isAnonymous)}/>
                                                            :
                                                            <DropWrapperV2>
                                                                <DragAndDropItemV2 id={question.id} index={key} moveItem={moveItem} findItem={findItem}>
                                                                    {
                                                                        (handleCanDrag) => (
                                                                            <PollQuestion propQuestion={question} handleSaveQuestion={saveQuestion} handleRemoveQuestion={handleRemoveQuestion} handleDuplicateQuestion={duplicateQuestion} lang={lang} handleCanDrag={handleCanDrag} isAnonymous={(questions.filter(el => el.type === PollTypes.NAME).length > 1 || isAnonymous)}/>
                                                                        )
                                                                    }
                                                                </DragAndDropItemV2>
                                                            </DropWrapperV2>
                                                    }
                                                </div>
                                            ))
                                        }
                                        {
                                            (questions && questions.length > 0) &&
                                            <DropZone position={questions.length}/>
                                        }
                                        <div className={"mt20"}>
                                            <IconButton size={"small"} onClick={handleOpenMenu}>
                                                <PlusIcon/>
                                            </IconButton>
                                            <Menu id="add_question_menu"
                                                  anchorEl={anchorEl}
                                                  keepMounted
                                                  open={Boolean(anchorEl)}
                                                  onClose={handleCloseMenu}
                                                  className={"menu-border-contrast-blue"}
                                            >
                                                {
                                                    types.map((type, index) => (
                                                        <MenuItem key={index} onClick={() => {handleAddQuestion({id:_.uniqueId(), type:type.type}, questions.length); handleCloseMenu()}}>
                                                            <type.icon className={"contrast-blue-icon w-20 h-20 mr-20"}/><span className={"text-contrast-blue"}>{_.upperFirst(t("webloom."+type.type))}</span>
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Menu>
                                        </div>
                                    </Col>
                                </Row>
                            </DndProvider>
                        </div>
                }
                {
                    questions && questions.length > 0 && !isProspect &&
                    <div className={"flex items-center justify-end mt-20 mr-15"}>
                        {
                            (!page.isSaved  && status !== SurveyStatus.RUNNING) &&
                            <span className={"ebloom-link-purple"} onClick={loading ? () => {} : () => {saveSurvey(SurveyStatus.DRAFT)}}>{loading ? <LoadingView size={16}/> : status === SurveyStatus.RUNNING ? _.upperFirst(t("core.save")) : _.upperFirst(t("core.saveParam", {param:(Roles.BACKOFFICE.some(el =>user.roles.includes(el)) ? "template" : t("core.collaborative.draft"))}))}</span>
                        }
                        {
                            (!Roles.BACKOFFICE.some(el =>user.roles.includes(el)) && (status !== SurveyStatus.RUNNING || (status === SurveyStatus.RUNNING && !page.isSaved))) &&
                            <Button disabled={loading} size={'sm'} className={"ebloom-btn-purple ml-10"} onClick={hasEndedFreeTrial ? () => setShowFreeTrialModal(true) : handleOpenModalSend}>{loading ? <LoadingView size={16}/> : (startDate && status !== SurveyStatus.RUNNING) ? _.upperFirst(t("core.collaborative.schedule")) : (startDate && status === SurveyStatus.RUNNING) ? _.upperFirst(t("core.save")) : _.upperFirst(t("core.send"))}</Button>
                        }
                    </div>
                }
            </PollFrame>
            <ConfirmModal handleClose={handleCloseModal} show={showModal} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t("webloom.resetParam", {param:t("webloom.poll")}))} handleChange={handleReset} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperFirst(t("core.confirm"))}>
                <p>{_.upperFirst(t("webloom.resetPollModal", {survey:t("webloom.thePoll")}))}</p>
            </ConfirmModal>
            <ConfirmModal handleClose={handleCloseModalSend} show={showModalSend} title={_.upperFirst(t("core.confirmation")) + " - " + _.upperFirst(t(SurveyStatus.RUNNING === status ? "core.updateParam" : "core.sendParam", {param:t("webloom.poll")}))} handleChange={() => {saveSurvey((startDate && status !== SurveyStatus.RUNNING) ? SurveyStatus.SCHEDULED : SurveyStatus.RUNNING)}} buttonLeftText={_.upperFirst(t("core.cancel"))} buttonRightText={_.upperFirst(t("core.confirm"))}>
                {
                    SurveyStatus.RUNNING !== status ?
                    <p><span>{startDate ?
                        _.upperFirst(t("webloom.sendPollModal", {recipients:displayRecipients(), startDate:moment(startDate).format("DD/MM/YY"), startHour:moment(startDate).format("HH:mm"),survey:t("webloom.thePoll")}))
                        :
                        _.upperFirst(t("webloom.sendPollModalNoStart", {recipients:displayRecipients(), survey:t("webloom.thePoll")}))
                    }</span> <span>
                    {
                        endDate ?
                            _.upperFirst(t("webloom.sendPollModalEnd", {endDate:moment(endDate).format("DD/MM/YY")}))
                            : _.upperFirst(t("webloom.sendPollModalNoEnd"))
                    }
                </span></p>
                    :
                    <div>
                        <p className={"my-5"}>{_.upperFirst(t("webloom.sendPollModalUpdated", {survey:t("webloom.thePoll")}))}</p>
                        {
                           hasNewQuestion &&
                            <p className={"my-5"}>{_.upperFirst(t("webloom.sendPollModalUpdatedAddQuestions"))}</p>
                        }
                        {
                            hasDeletedQuestion &&
                            <p className={"my-5"}>{_.upperFirst(t("webloom.sendPollModalUpdatedRemoveQuestions"))}</p>
                        }
                        <div className={"my-10 flex items-top justify-center"}>
                            <Checkbox className={"checkbox-purple p-0"} checked={sendNotification} onChange={() => {setSendNotification(!sendNotification)}}/>
                            <div className={"ml-5"}>{_.upperFirst(t(hasModifiedRecipients ? "webloom.sendPollModalUpdatedModifiedRecipientsCheckbox" :"webloom.sendPollModalUpdatedCheckbox", {recipients:displayRecipients()}))}</div>
                        </div>
                    </div>
                }

            </ConfirmModal>
            <FreeTrialModal handleClose={() => {setShowFreeTrialModal(false)}} show={showFreeTrialModal} text={""}/>
        </>
    )
}

export default WeBloomCreatePoll;