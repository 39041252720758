import React, {useEffect, useState} from "react";
import HttpApi from "../../../../../httpApi";
import {
    createErrorNotification,
    createSuccessNotification,
} from "../../../../../Utils/Notification";
import { Row } from "react-bootstrap";
import CardDefault from "../../../../Core/Modules/Cards/CardDefault";
import CompanyModel from "../../../../../models/Company";
import SideForm from "../../../../Core/Modules/Views-Tables-Lists/SideForm";
import _ from "lodash";
import TableViewV2 from "../../../../Core/Modules/Views-Tables-Lists/TableViewV2";
import TextFieldCustom from "../../../../Core/Modules/Input/TextFieldCustom";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import {useTranslation} from "react-multi-lang";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import {CompanyTypes, FullContrastPurpleTextField, Mail, Roles} from "../../../../../Utils/Global";
import moment from "moment";
import {getLanguages} from "../../../../../lang/translations";
import {useUserData} from "../../../../../hooks/userHook";

const CompanyEditor = (props) => {
    const [companies, setCompanies] = useState([]);
    const [modules, setModules] = useState([]);
    const [sideFormOpen, setSideFormOpen] = useState(false);
    const [formTitle, setFormTitle] = useState("");
    const [company, setCompany] = useState({id:null, name:"", id_module:2, module:"very_happy", type:CompanyTypes.CLIENT, start_date:moment().format("YYYY-MM-DD"), contract_length:"12", admin_mail:"", admin_name:"", admin_lang:"en", max_invite:null, mail_system:Mail.MAILJET, notif_time:"10:00", priceit_url:null, company_group : -1, account_manager:"Margot"});
    const [tablePage, setTablePage] = useState(0);
    const [showActive, setShowActive] = useState(true);

    const t = useTranslation();
    const user = useUserData();
    const isDatatopia = user.roles.includes("datatopia_backoffice");

    let CompanyModelClone = _.cloneDeep(CompanyModel);
    CompanyModelClone.crud.delete.url = "/companies/delete";

    const mailSystems = [{value:Mail.MAILJET, name:"Mailjet"}, {value:Mail.MANDRILL, name: "Mandrill"}, {value:Mail.EC2_SMTP, name:"EC2 - SMTP"}, {value:Mail.MAILERSEND, name:"MailerSend"}];
    const accountManagers = [{value:'Jasper', name:'Jasper'}, {value:'Margot', name:'Margot'}];


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setCompany(prevState => {
            let company = {...prevState};
            company[name] = value;
            return company;
        })
    };

    const handleDelete = () => {
        fetchCompanies();
    };

    const handleTablePage = (event, newPage) => {
        setTablePage(newPage);
    }


    const fetchCompanies = () => {
        HttpApi.getV2("/companies/findAll").then(response => {
            if(response && response.data){
                if(user.roles.includes("global_backoffice") || isDatatopia){
                    setCompanies(response.data);
                }else if(user.roles.includes("demo_backoffice")){
                    setCompanies(response.data.filter(el => el.type === CompanyTypes.DEMO));
                }
                setShowActive(true);
            }

        })
    };

    const fetchDeleted = () => {
        HttpApi.getV2("/companies/findDeleted").then(response => {
            if(response && response.data){
                setCompanies(response.data);
                setShowActive(false);
            }
        })
    }

    const fetchModules = () => {
        if(!isDatatopia){
            HttpApi.getV2("/modules/getAll").then(results => {
                if(results && results.data){
                    setModules(results.data);
                }
            })
        }
    };

    const saveCompany = () => {
        let url = "/companies/create";
        let data = {name:company.name, code:company.code, code_admin:company.code_admin, id_module: company.id_module, type:company.type, priceit_url:company.priceit_url,company_group : company.company_group ? company.company_group : null, account_manager: company.account_manager};
        let message1 = "created";
        let message2 = "creating";
        let HttpApiCall = HttpApi.postV2;

        if(company.id){ //EDITING
            url = "/companies/update";
            data.id = company.id;
            message1 = "edited";
            message2 = "editing";
            HttpApiCall = HttpApi.put;
        }else{
            data.admin_mail = company.admin_mail;
            data.admin_name = company.admin_name;
            data.admin_lang = company.admin_lang;
            data.mail_system = company.mail_system;
            data.notif_time = company.notif_time;
            data.max_invite = parseInt(company.max_invite);
            data.start_date = company.start_date;
            data.contract_length = parseInt(company.contract_length);
        }
        if(!company.id && company.id_module === 4 && !company.priceit_url){
            createErrorNotification("Error!", "Please fill in a priceit url for your free account");
        }else{
            HttpApiCall(url, data).then(response => {
                if(response){
                    createSuccessNotification("Success !", "Company successfully " + message1);
                    resetCompanyEditState();
                    setSideFormOpen(false);
                    fetchCompanies();
                }else{
                    createErrorNotification("Error !", "An error occurred while " + message2 + " the company");
                }
            })
        }
    };

    const reactivateCompany = (id) => event => {
        HttpApi.put("/companies/reactivate", {id:id}).then(() => {
            fetchCompanies();
        })
    }

    const randomCode = (size) => {
        const dict = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'm', 'n', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N','P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        let token = "";
        for(let i=0; i<size;i++){
            let index = Math.floor((Math.random()*(dict.length-1)));
            token += dict[index];
        }

        return token;
    };

    const randomCompanyCode = (type) => event => {
        event.preventDefault();
        const size = type === "code_admin" ? 12 : 8;
        const code = randomCode(size);
        setCompany(prevState => {
            let company = {...prevState};
            company[type] = code;
            return company;
        })

    };

    const resetCompanyEditState = () => {
        setCompany({id:null, name:"", id_module:2, module:"very_happy", type:CompanyTypes.CLIENT, start_date:moment().format("YYYY-MM-DD"), contract_length:"12", admin_mail:"", admin_name:"", admin_lang:"en", max_invite:null, mail_system:Mail.MAILJET, notif_time:"10:00",company_group: -1, account_manager: "Margot"});
    };

    useEffect(() => {
        fetchCompanies();
        fetchModules();
    },[]);




    const toggleDrawer = (open, toEdit) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        if(toEdit){
            setCompany(toEdit);
            setFormTitle(_.capitalize(t("core.update")) + " " + _.capitalize(t("core.company.company")))
        }else{
            resetCompanyEditState();
            setFormTitle(_.capitalize(t("core.create")) + " " + _.capitalize(t("core.company.company")))
        }
        setSideFormOpen(open);


    };



        return (
            <>
                <CardDefault title={_.capitalize(t("core.company.companies"))} showButton={Roles.BACKOFFICE_GLOBAL_DATATOPIA.some(el =>user.roles.includes(el))} handleRightButton={toggleDrawer(true)} showExtraRightButton={user.roles.includes("global_backoffice")} extraRightButtonText={showActive ? "inactive" : "active"} handleExtraRightButton={showActive ? fetchDeleted : fetchCompanies}>
                    {
                        showActive ?
                            <TableViewV2 items={companies} model={CompanyModelClone} handleEdit={toggleDrawer} handleDelete={handleDelete} tablePage={tablePage} handleTablePage={handleTablePage}/>
                            :
                            <TableViewV2 items={companies} model={CompanyModelClone} showEdit={false} showDelete={false} showDetail iconDetail={<PowerSettingsNewIcon/>} textDetail={"Reactivate"} variantDetail={"purple-outline"} handleView={reactivateCompany}  tablePage={tablePage} handleTablePage={handleTablePage}/>
                    }

                </CardDefault>
                <SideForm sideFormOpen={sideFormOpen} formTitle={formTitle} toggleDrawer={toggleDrawer} handleSave={saveCompany}>
                    <Row>
                        <TextFieldCustom model={CompanyModel.name} value={company.name} handleChange={handleChange} size={"small"}/>
                    </Row>
                    {
                        !isDatatopia &&
                        <Row>
                            <TextFieldCustom model={CompanyModel.id_module} value={company.id_module} handleChange={handleChange} size={"small"}>
                                {
                                    modules.map(module => (
                                        <MenuItem key={module.id} value={module.id}>{module.name}</MenuItem>
                                    ))
                                }
                            </TextFieldCustom>
                        </Row>

                    }
                    {
                        !isDatatopia &&
                        <Row>
                            <TextFieldCustom model={CompanyModel.type} value={company.type} handleChange={handleChange} size={"small"}>
                                {
                                    Object.values(CompanyTypes).map((type, index) => (
                                        <MenuItem key={index} value={type}>{_.upperFirst(type)}</MenuItem>
                                    ))
                                }
                            </TextFieldCustom>
                        </Row>
                    }
                    {
                        !isDatatopia && company.id === null && company.type !== CompanyTypes.GROUP &&
                        <Row className={"py-10"}>
                            <FullContrastPurpleTextField select name={"company_group"} variant={"outlined"} label={"Link to a group"}  value={company.company_group} onChange={handleChange} size={"small"}>
                                <MenuItem key={-1} value={-1}>
                                    None
                                </MenuItem>
                                {
                                    companies.filter(el => el.type === "group").map((el, index) => (
                                        <MenuItem key={index} value={el.id}>
                                            {_.upperFirst(el.name)}
                                        </MenuItem>
                                    ))
                                }
                            </FullContrastPurpleTextField>
                        </Row>
                    }
                    {
                        (!company.id && !isDatatopia) &&
                        <div className={"pl10 pr10"}>
                            {
                                company.type !== CompanyTypes.GROUP &&
                                <Row className={"mb10 mt10"}>
                                    <FullContrastPurpleTextField size={"small"} variant={"outlined"} label={"Contract Date"} type={"date"} value={company.start_date} name={"start_date"} onChange={handleChange} required fullWidth/>
                                </Row>
                            }
                            {
                                company.type !== CompanyTypes.GROUP &&
                                <Row className={"mb10 mt10"}>
                                    <FullContrastPurpleTextField size={"small"} variant={"outlined"} label={"Contract Length"} value={company.contract_length} name={"contract_length"} onChange={handleChange} required fullWidth/>
                                </Row>
                            }
                            {
                                company.type !== CompanyTypes.GROUP &&
                                <Row className={"mb10 mt10"}>
                                    <FullContrastPurpleTextField size={"small"} variant={"outlined"} label={"Admin mail"} type={"email"} fullWidth name={"admin_mail"} value={company.admin_mail} onChange={handleChange}/>
                                </Row>
                            }
                            {
                                company.type !== CompanyTypes.GROUP &&
                                <Row className={"mb10 mt10"}>
                                    <FullContrastPurpleTextField size={"small"} variant={"outlined"} label={"Admin name"} fullWidth name={"admin_name"} value={company.admin_name} onChange={handleChange}/>
                                </Row>
                            }
                            {
                                company.type !== CompanyTypes.GROUP &&
                                <Row className={"mb10 mt10"}>
                                    <FullContrastPurpleTextField size={"small"} variant={"outlined"} select label={"Admin lang"} fullWidth name={"admin_lang"} value={company.admin_lang} onChange={handleChange}>
                                        {
                                            getLanguages().map((lang, index) => (
                                                <MenuItem key={index} value={lang.value}>{lang.name}</MenuItem>
                                            ))
                                        }
                                    </FullContrastPurpleTextField>
                                </Row>
                            }
                            <Row className={"mb10 mt10"}>
                                <FullContrastPurpleTextField size={"small"} variant={"outlined"} label={"Number user contract"} type={"number"} fullWidth required name={"max_invite"} value={company.max_invite} onChange={handleChange}/>
                            </Row>
                            <Row className={"mb10 mt10"}>
                                <FullContrastPurpleTextField size={"small"} variant={"outlined"} select label={"Mail System"} fullWidth required name={"mail_system"} value={company.mail_system} onChange={handleChange}>
                                    {
                                        mailSystems.map((system,index) => (
                                            <MenuItem key={index} value={system.value}>{system.name}</MenuItem>
                                        ))
                                    }
                                </FullContrastPurpleTextField>
                            </Row>
                            <Row className={"mb10 mt10"}>
                                <FullContrastPurpleTextField size={"small"} variant={"outlined"} type={"time"} label={"Notif Time"} fullWidth name={"notif_time"} value={company.notif_time} onChange={handleChange} />
                            </Row>
                            <Row className={"mb10 mt10"}>
                                <FullContrastPurpleTextField size={"small"} variant={"outlined"} select label={"Account Manager"} fullWidth required name={"account_manager"} value={company.account_manager} onChange={handleChange}>
                                    {
                                        accountManagers.map((manager,index) => (
                                            <MenuItem key={index} value={manager.value}>{manager.name}</MenuItem>
                                        ))
                                    }
                                </FullContrastPurpleTextField>
                            </Row>
                        </div>
                    }
                    {
                        company.id_module === 4 && //free module
                        <Row className={"mb-10 mt-10"}>
                            <FullContrastPurpleTextField size={"small"} variant={"outlined"} label={"Price it URL"} fullWidth name={"priceit_url"} value={company.priceit_url} onChange={handleChange} />
                        </Row>
                    }
                </SideForm>
            </>
        )

}

export default CompanyEditor;