import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-multi-lang";
import ConfirmModal from "./ConfirmModal";
import _ from "lodash";
import HttpApi from "../../../../httpApi";
import {notifyError} from "../../../../Utils/Notification";
import priceIt from "../../symbols/priceIt.jpg";
import {Button} from "react-bootstrap";
import {useUserData} from "../../../../hooks/userHook";
import {Modules, Roles} from "../../../../Utils/Global";

const FreeTrialModal = (props) => {
    const [priceItUrl, setPriceItUrl] = useState(null);

    const {show, handleClose, text, showForm, title} = props;

    const t = useTranslation();
    const user = useUserData();

    useEffect(() => {
        if(Modules.FREE.includes(user.module) && Roles.ADMIN.some(el =>user.roles.includes(el))){
            HttpApi.getV2("/companies/getPriceitUrl").then(response => {
                if(response && response.data){
                    setPriceItUrl(response.data);
                }else{
                    setPriceItUrl(null);
                }
            }).catch(error => {
                notifyError(error);
            })
        }
    }, [])


    return(
        <ConfirmModal size={"lg"} handleClose={handleClose} show={show} title={_.upperFirst(t(title))} showButtons={false}>
            <p>{_.upperFirst(t(text))}</p>
            {
                (showForm && priceItUrl) &&
                <div className={"pointer text-center"} onClick={() => {window.open(priceItUrl, '_blank').focus()}}>
                    <img src={priceIt} alt={"pricing"}/>
                    <Button className={"ebloom-btn-purple mt-10"}>{_.upperFirst(t("free.getQuote"))}</Button>
                </div>

            }
        </ConfirmModal>
    )
}

FreeTrialModal.propTypes = {
    show:PropTypes.bool.isRequired,
    handleClose:PropTypes.func.isRequired,
    text:PropTypes.string,
    showForm:PropTypes.bool,
    title:PropTypes.string
}

FreeTrialModal.defaultProps = {
    text:"free.premiumText",
    showForm:true,
    title:"free.premiumAvailable"
}

export default FreeTrialModal;