import {Mail, Modules} from "../Utils/Global";
import {ModelTypes} from "./Types";
import HttpApi from "../httpApi";

const Setting = {

    initializeOptions : () => {
        HttpApi.get("/palette/getAll").then((response) => {
            if(response.data && response.data) {
                Setting.colors.value.form.selectOptions.options = response.data.map(el => {
                    return {
                        value: el.id,
                        name : <div className={"w-full flex flex-row justify-around items-stretch"}>
                            <div className={"w-full flex justify-start mr-10"}><p>{el.name}</p></div>
                            <div  className={"flex flex-row justify-center items-center"}>
                                <div style={{backgroundColor: el.primary}} className={`w-15 h-15`}></div>
                                {/*<div style={{backgroundColor: el.secondary}} className={`w-15 h-15`}></div>
                                <div style={{backgroundColor: el.tertiary}} className={`w-15 h-15`}></div>
                                <div style={{backgroundColor: el.quaternary}} className={`w-15 h-15`}></div>
                                <div style={{backgroundColor: el.quinary}} className={`w-15 h-15`}></div>
                                <div style={{backgroundColor: el.background}} className={`w-15 h-15`}></div>*/}
                            </div>

                        </div>
                    }
                })
            }
        })
    },

    credits: {
        crud: {
            create: "/settings/create",
            update: "/settings/update",
            delete: "",
        },
        packages: [...Modules.MORE_THAN_HAPPY_NOT_FREE],
        editable: false,
        labelType: "admin.account.credits",
        labelValue:(value) => {return(value)},
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },
        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"credits",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },
        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"credits-value",
                label:"value",
                select:false,
                multiline:false,
                password:false,
                rows:1,
                required:true,
                name:"creditsValue",
            }
        },
    },

    nbCb:{
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [...Modules.ALL],
        editable: false,
        labelType:"admin.account.nbCb",
        labelValue:(value) => {return(value === 1000 ? "core.unlimited" : value)},
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },


        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"nbCb",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"nbcb-value",
                label:"value",
                select:false,
                multiline:false,
                password:false,
                rows:1,
                required:true,
                name:"nbcbValue",
            }
        },
    },

    minTeamMember:{
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [...Modules.MORE_THAN_HAPPY],
        editable: false,
        labelType:"admin.account.minMemberTeam",
        labelValue:(value) => {return(value)},
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },


        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"minTeamMember",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"minMemberTeam-value",
                label:"value",
                select:false,
                multiline:false,
                password:false,
                rows:1,
                required:true,
                name:"minMemberTeamValue",
            }
        },
    },
    maxUser:{
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [...Modules.ALL],
        editable: false,
        labelType:"admin.account.maxUser",
        labelValue:(value) => {return(value === 1000 ? "core.unlimited" : value)},
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },


        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"maxUser",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"maxUser-value",
                label:"value",
                select:false,
                multiline:false,
                password:false,
                rows:1,
                required:true,
                name:"maxUserValue",
            }
        },
    },
    maxInvite:{
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [],
        editable: false,
        labelType:"admin.account.maxInvite",
        labelValue:(value) => {return(value)},
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },


        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"maxInvite",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"maxInvite-value",
                label:"value",
                select:false,
                multiline:false,
                password:false,
                rows:1,
                required:true,
                name:"maxInviteValue",
            }
        },
    },
    mailSystem:{
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [],
        editable: false,
        labelType:"admin.account.mailSystem",
        labelValue:(value) => {return(value)},
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"mailSystem",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"mailSystem-value",
                label:"value",
                select:true,
                selectOptions:{
                    options:[{value:Mail.MAILJET, name:"Mailjet"}, {value:Mail.MANDRILL, name: "Mandrill"}, {value:Mail.EC2_SMTP, name:"EC2 - SMTP"},  {value:Mail.MAILERSEND, name:"MailerSend"}]
                },
                multiline:false,
                password:false,
                rows:1,
                required:true,
                name:"mailSystemValue",
            }
        },
    },
    window:{
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [...Modules.MORE_THAN_HAPPY_NOT_FREE],
        editable: true,
        labelType:"admin.account.window",
        labelValue:(value) => {
            return(value)
        },
        tooltip:"admin.account.windowTooltip",
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"type",
            type:ModelTypes.TEXT,
            label:"window",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"window-value",
                label:"value",
                select:true,
                selectOptions:{
                    options:[{name:"3 jours", value:3},{name:"5 jours", value:5},{name:"10 jours", value:10}]
                },
                multiline:false,
                password:false,
                rows:1,
                required:true,
                name:"windowValue",
            }
        },
    },
    notifTime:{
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [...Modules.ALL],
        editable: true,
        labelType:"admin.account.notifTime",
        labelValue:(value) => {
            value = value.toString();
            if(value.length === 3){
                value = "0" + value;
            }
            value = value.substring(0,2) + ":" + value.substring(2, value.length);
            return(value)
        },
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"notifTime",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"notifTime-value",
                label:"value",
                select:true,
                selectOptions:{
                    options:[
                        { name: '07:00', value: 700 },
                        { name: '07:15', value: 715 },
                        { name: '07:30', value: 730 },
                        { name: '07:45', value: 745 },
                        { name: '08:00', value: 800 },
                        { name: '08:15', value: 815 },
                        { name: '08:30', value: 830 },
                        { name: '08:45', value: 845 },
                        { name: '09:00', value: 900 },
                        { name: '09:15', value: 915 },
                        { name: '09:30', value: 930 },
                        { name: '09:45', value: 945 },
                        { name: '10:00', value: 1000 },
                        { name: '10:15', value: 1015 },
                        { name: '10:30', value: 1030 },
                        { name: '10:45', value: 1045 },
                        { name: '11:00', value: 1100 },
                        { name: '11:15', value: 1115 },
                        { name: '11:30', value: 1130 },
                        { name: '11:45', value: 1145 },
                        { name: '12:00', value: 1200 },
                        { name: '12:15', value: 1215 },
                        { name: '12:30', value: 1230 },
                        { name: '12:45', value: 1245 },
                        { name: '13:00', value: 1300 },
                        { name: '13:15', value: 1315 },
                        { name: '13:30', value: 1330 },
                        { name: '13:45', value: 1345 },
                        { name: '14:00', value: 1400 },
                        { name: '14:15', value: 1415 },
                        { name: '14:30', value: 1430 },
                        { name: '14:45', value: 1445 },
                        { name: '15:00', value: 1500 },
                        { name: '15:15', value: 1515 },
                        { name: '15:30', value: 1530 },
                        { name: '15:45', value: 1545 },
                        { name: '16:00', value: 1600 },
                        { name: '16:15', value: 1615 },
                        { name: '16:30', value: 1630 },
                        { name: '16:45', value: 1645 },
                        { name: '17:00', value: 1700 },
                        { name: '17:15', value: 1715 },
                        { name: '17:30', value: 1730 },
                        { name: '17:45', value: 1745 },
                        { name: '18:00', value: 1800 },
                        { name: '18:15', value: 1815 },
                        { name: '18:30', value: 1830 },
                        { name: '18:45', value: 1845 },
                        { name: '19:00', value: 1900 },
                        { name: '19:15', value: 1915 },
                        { name: '19:30', value: 1930 },
                        { name: '19:45', value: 1945 },
                        { name: '20:00', value: 2000 }
                    ]
                },
                multiline:false,
                password:false,
                rows:1,
                required:true,
                name:"notifTimeValue",
            }
        },
    },
    reminder:{
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [...Modules.ALL],
        editable: true,
        labelType:"admin.account.reminder",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        tooltip:"admin.account.reminderTooltip",
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"reminder",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"reminder-value",
                label:"value",
                select:false,
                multiline:false,
                switch:true,
                password:false,
                rows:1,
                required:true,
                name:"reminderValue",
            }
        },
    },
    reminderMorning:{
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [...Modules.ALL],
        editable: true,
        labelType:"admin.account.reminderMorning",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        tooltip:"admin.account.reminderMorningTooltip",
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"reminderMorning",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"reminderMorning-value",
                label:"value",
                select:false,
                multiline:false,
                switch:true,
                password:false,
                rows:1,
                required:true,
                name:"reminderMorningValue",
            }
        },
    },
    reminderMiddle:{
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [...Modules.MORE_THAN_HAPPY],
        editable: true,
        labelType:"admin.account.reminderMiddle",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        tooltip:"admin.account.reminderMiddleTooltip",
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"reminderMiddle",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"reminderMiddle-value",
                label:"value",
                select:false,
                multiline:false,
                password:false,
                switch:true,
                rows:1,
                required:true,
                name:"reminderMiddleValue",
            }
        },
    },
    reminderLastDay:{
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [...Modules.MORE_THAN_HAPPY],
        editable: true,
        labelType:"admin.account.reminderLast",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        tooltip:"admin.account.reminderLastTooltip",
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"reminderLastDay",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"reminderLastDay-value",
                label:"value",
                select:false,
                multiline:false,
                switch:true,
                password:false,
                rows:1,
                required:true,
                name:"reminderLastDayValue",
            }
        },
    },
    publicHoliday:{
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [...Modules.NOT_FREE],
        editable: true,
        labelType:"admin.account.publicHoliday",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"publicHoliday",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"publicHoliday-value",
                label:"value",
                select:false,
                multiline:false,
                switch:true,
                password:false,
                rows:1,
                required:true,
                name:"publicHolidayValue",
            }
        },
    },
    blueCollar:{
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [],
        editable: false,
        labelType:"admin.account.blueCollar",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"blueCollar",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"blueCollar-value",
                label:"value",
                select:false,
                multiline:false,
                switch:true,
                password:false,
                rows:1,
                required:true,
                name:"blueCollarValue",
            }
        },
    },
    productTour : {
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [],
        editable: false,
        labelType:"admin.account.productTour",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"productTour",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"productTour-value",
                label:"value",
                select:false,
                multiline:false,
                switch:true,
                password:false,
                rows:1,
                required:true,
                name:"productTourValue",
            }
        },
    },
    customCategories : {
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [],
        editable: false,
        labelType:"admin.customCategories.title",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"customCategories",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"customCategories-value",
                label:"value",
                select:false,
                multiline:false,
                switch:true,
                password:false,
                rows:1,
                required:true,
                name:"customCategoriesValue",
            }
        },
    },
    translation : {
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [...Modules.MORE_THAN_HAPPY_NOT_FREE],
        editable: true,
        labelType:"admin.account.translation",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        tooltip:"admin.account.translationTooltip",
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"translation",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"translation-value",
                label:"value",
                select:false,
                multiline:false,
                switch:true,
                password:false,
                rows:1,
                required:true,
                name:"translationValue",
            }
        },
    },
    showTranslation : {
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [...Modules.MORE_THAN_HAPPY_NOT_FREE],
        editable: false,
        labelType:"admin.account.showTranslation",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"showTranslation",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"showTranslation-value",
                label:"value",
                select:false,
                multiline:false,
                switch:true,
                password:false,
                rows:1,
                required:true,
                name:"showTranslationValue",
            }
        },
    },
    showFreeTrial : {
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [],
        editable: false,
        labelType:"admin.showFreeTrial",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"showFreeTrial",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"showFreeTrial-value",
                label:"value",
                select:false,
                multiline:false,
                switch:true,
                password:false,
                rows:1,
                required:true,
                name:"showFreeTrialValue",
            }
        },
    },
    googleReviews : {
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [],
        editable: false,
        labelType:"admin.googleReviews",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"googleReviews",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"googleReviews-value",
                label:"value",
                select:false,
                multiline:false,
                password:false,
                switch:true,
                rows:1,
                required:true,
                name:"googleReviewsValue",
            }
        },
    },
    alert : {
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [],
        editable: false,
        labelType:"admin.alert",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"alert",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"alert-value",
                label:"value",
                select:false,
                multiline:false,
                password:false,
                switch:true,
                rows:1,
                required:true,
                name:"alertValue",
            }
        },
    },
    colors:{
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [...Modules.MORE_THAN_HAPPY_NOT_FREE],
        editable: false,
        labelType:"admin.account.colors",
        labelValue:(value) => {
            return(value)
        },
        tooltip:"admin.account.colors",
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"type",
            type:ModelTypes.TEXT,
            label:"colors",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.NUMBER,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"colors-value",
                label:"value",
                select:true,
                selectOptions:{
                    options:[]
                },
                multiline:false,
                password:false,
                rows:1,
                required:true,
                name:"colorsValue",
            }
        },
    },
    blueCollarLibrary: {
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [],
        editable: false,
        labelType:"backoffice.blueCollarLibrary",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"blueCollarLibrary",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.BOOLEAN,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"blueCollarLibrary-value",
                label:"value",
                select:false,
                multiline:false,
                password:false,
                switch:true,
                rows:1,
                required:true,
                name:"blueCollarLibraryValue",
            }
        },
    },
    benchmark: {
        crud:{
            create:"/settings/create",
            update:"/settings/update",
            delete:"",
        },
        packages : [],
        editable: false,
        labelType:"backoffice.benchmark",
        labelValue:(value) => {
            return(value ? "admin.account.activate" : "admin.account.deactivate");
        },
        id:{
            id:"id",
            type:ModelTypes.NUMBER,
            label:"id",
            table:{
                show:false
            },
            list:{

            },
            form:{

            }
        },

        type:{
            id:"value",
            type:ModelTypes.TEXT,
            label:"benchmark",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:false,
            }
        },

        value:{
            id:"value",
            type:ModelTypes.BOOLEAN,
            label:"value",
            table:{
                show:false
            },
            list:{

            },
            form:{
                show:true,
                id:"benchmark-value",
                label:"value",
                select:false,
                multiline:false,
                password:false,
                switch:true,
                rows:1,
                required:true,
                name:"benchmarkValue",
            }
        },
    },
};

export default Setting;